































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import MENUS from '@common-src/json/menu.json';

@Component
export default class ReportIndexComponent extends BaseComponent {
    get ChartType() {
        return `/report/${this.$route.params.chartType}`;
    }
    get ChartMenuTitle() {
        return _.get(_.find(this.ChartMenuList, (item: any) => item.route.indexOf(this.ChartType) > -1), 'name');
    }
    get ChartMenuList() {
        return _.get(_.find(MENUS, item => item.route === 'report'), 'subMenus');
    }

    get ReportMenuList() {
        return _.get(_.find(this.ChartMenuList, (item: any) => item.route.indexOf(this.ChartType) > -1), 'subMenus');
    }

    get SelectedKey(): string {
        return this.$route.path;
    }
}


var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "report-index-component table-component",
      attrs: { id: "report_index_component" },
    },
    [
      _c("div", { staticClass: "page-header report-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("图表")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v(_vm._s(_vm.ChartMenuTitle))]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-sider",
                { staticClass: "page-menu-sider", attrs: { theme: "light" } },
                [
                  _c(
                    "a-card",
                    {
                      staticClass: "dark-card",
                      attrs: { title: "图表分类", bordered: false },
                    },
                    [
                      _c(
                        "a-menu",
                        {
                          attrs: {
                            "selected-keys": [_vm.SelectedKey],
                            theme: "dark",
                          },
                        },
                        [
                          _vm._l(_vm.ReportMenuList, function (item) {
                            return [
                              _c(
                                "a-menu-item",
                                { key: item.route },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        rel: "noopener noreferrer",
                                        to: item.route,
                                      },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-layout-content",
                [
                  _c("router-view", {
                    staticClass: "page-body-margin-left-16",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }